import { ReactNode } from 'react';
import { Col, Flex, Typography, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { distanceParser } from '@shopopop/backoffice-frontend-utils';

const { Text } = Typography;

interface DeliveryDistanceProps {
  distanceInfos: {
    isLoading: boolean;
    isValidatedDistance: boolean | undefined;
    distance: number | null;
  };
}

function DeliveryDistance({ distanceInfos }: DeliveryDistanceProps): ReactNode {
  const { t } = useTranslation();
  const { isLoading, isValidatedDistance, distance } = distanceInfos;
  const isDanger: boolean = isValidatedDistance === false && !isLoading;

  return (
    <Col xs={24} style={{ marginBottom: 16 }}>
      <Flex vertical>
        <Text {...(isDanger ? { type: 'danger' } : {})}>
          <>{t('DELIVERY_DISTANCE')} : </>
          {isLoading && <Spin size="small" />}
          {distance && !isLoading && distanceParser({ value: distance || null, unit: 'm' })}
          {!distance && !isLoading && ' - '}
        </Text>
        {isDanger && <Text type="danger">{t('DISTANCE_TOO_LONG')}</Text>}
      </Flex>
    </Col>
  );
}

export default DeliveryDistance;
