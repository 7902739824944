/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/useRouter';
// Components
import { Button, Drawer, Form, Tabs } from 'antd';
import addPropertyDeactivationType from '../../../utils/addPropertyDeactivationType';
import Error504 from '../../Errors/Error504';
import UserManagementHistory from '../../User/Moderation/UserManagementHistory';
import UserBanishmentFormDrawer from './UserBanishmentFormDrawer';
import UserCommentFormDrawer from './UserCommentFormDrawer';
// Utils
import { createHistoryObjectOldBo } from '../../../utils/createHistoryObjectOldBo';
import EmptyModerationDrawer from '../../EmptyComponents/EmptyModerationDrawer';
import UserRatingHistory from '../../User/Moderation/UserRatingHistory';
// Services
import {
  readCommentDriveHistory,
  readDeactivatedUserHistory,
  readInternCommentHistory,
  readReactivatedUserHistory
} from '../../../services/apiEndPoints/users/moderation';

/**
  * UserModerationHistoryDrawer
  * @param {Object} props
  * @param {boolean} props.visible
  * @param {Function} props.setVisible
  * @param {boolean} props.isBanUser
  * @param {Function} setIsBanUser
  * @param {Object} userDeactivationReasons
  * @param {Array} internalCommentBOV1
  * @param {Function} getUserDeactivationReasons
  * @return {JSX.Element}
 */
function UserModerationHistoryDrawer({ visible, setVisible, isBanUser, setIsBanUser, userDeactivationReasons, internalCommentBOV1, getUserDeactivationReasons }) {
  const router = useRouter();
  const { t } = useTranslation();
  const [commentForm] = Form.useForm();
  const userId = router.query.id;
  const [childVisible, setChildVisible] = useState(false);
  const [visibleCommentDrawer, setVisibleCommentDrawer] = useState(false);
  const [deactivatedUserHistory, setDeactivatedUserHistory] = useState([]);
  const [reactivatedUserHistory, setReactivatedUserHistory] = useState([]);
  const [driveCommentHistory, setDriveCommentHistory] = useState([]);
  const [statusOnError, setStatusOnError] = useState(false);
  const [isBanishFromAllDrive, setIsBanishFromAllDrive] = useState(false);
  const [userInternComments, setUserInternComments] = useState([]);
  const [activeTab, setActiveTab] = useState('userManagementHistory');
  const [loading, setLoading] = useState({
    deactivatedUserHistory: true,
    reactivatedUserHistory: true,
    driveCommentHistory: true,
    userInternComments: true });

  useEffect(() => {
    getDeactivatedUserHistory();
    getReactivatedUserHistory();
    getCommentDriveHistory();
    getInternCommentHistory();
  }, [isBanUser, isBanishFromAllDrive, userInternComments?.length]);

  useEffect(() => {
    if (!visibleCommentDrawer) {
      commentForm.resetFields();
    }
  }, [visibleCommentDrawer]);

  /**
   * getDeactivatedUserHistory - Get the user deactivation history
   * @return {void}
   */
  const getDeactivatedUserHistory = () => {
    readDeactivatedUserHistory(userId).then((response) => {
      if (response.type === 'success') {
        if (response.data.length > 0) {
          setDeactivatedUserHistory(response.data);
        }
        if (isBanUser && response.data.length === 0) {
          setDeactivatedUserHistory([createHistoryObjectOldBo(userDeactivationReasons)]);
        }
        setStatusOnError(false);
      } else {
        setStatusOnError(true);
      }
      setLoading((prevState) => ({ ...prevState, deactivatedUserHistory: false }));
    });
  };

  /**
   * getCommentHistoryHistory - Get the user comment drive history
   * @return {void}
   */
  const getCommentDriveHistory = () => {
    readCommentDriveHistory(userId).then((response) => {
      if (response.type === 'success') {
        setStatusOnError(false);
        setDriveCommentHistory(addPropertyDeactivationType(response.data, 'DRIVE_COMMENT'));
      } else {
        setStatusOnError(true);
      }
      setLoading((prevState) => ({ ...prevState, driveCommentHistory: false }));
    });
  };

  /**
   * getReactivatedUserHistory - Get the user reactivation history
   * @return {void}
   */
  const getReactivatedUserHistory = () => {
    readReactivatedUserHistory(userId).then((response) => {
      if (response.type === 'success') {
        setStatusOnError(false);
        setReactivatedUserHistory(response.data);
      } else {
        setStatusOnError(true);
      }
      setLoading((prevState) => ({ ...prevState, reactivatedUserHistory: false }));
    });
  };

  /**
   * getInternCommentHistory - Get the user comment history
   * @return {Promise<void>}
   */
  const getInternCommentHistory = async() => {
    readInternCommentHistory(userId).then((response) => {
      if (response.type === 'success') {
        setStatusOnError(false);
        setUserInternComments(addPropertyDeactivationType(response.data, 'INTERN_COMMENT_V2'));
      } else {
        setStatusOnError(true);
      }
      setLoading((prevState) => ({ ...prevState, userInternComments: false }));
    });
  };

  /**
   * onClose - Close the drawer
   * @return {void}
   */
  const onClose = () => {
    if (childVisible) {
      setChildVisible(false);
    } else {
      setVisible(false);
    }
    setActiveTab('userManagementHistory');
  };

  const isModerationHistoryIsEmpty =
  (deactivatedUserHistory.length ||
    internalCommentBOV1.length ||
    userInternComments.length ||
    driveCommentHistory.length ||
    reactivatedUserHistory.length);
  /**
   * showUserBanishmentFormDrawer - Show the banishment form drawer
   * @return {void}
   */
  const showUserBanishmentFormDrawer = () => {
    setChildVisible(true);
  };

  /**
   * showUserCommentFormDrawer - Show the banishment form drawer
   * @return {void}
   */
  const showUserCommentFormDrawer = () => {
    setVisibleCommentDrawer(true);
  };

  const tabsItems = [
    {
      label: t('MANAGEMENT'),
      key: 'userManagementHistory',
      children: isModerationHistoryIsEmpty ? <UserManagementHistory
        deactivatedUserHistory={deactivatedUserHistory}
        reactivatedUserHistory={reactivatedUserHistory}
        isBanUser={isBanUser}
        setIsBanUser={setIsBanUser}
        setVisible={setVisible}
        setIsBanishFromAllDrive={setIsBanishFromAllDrive}
        commentDriveHistory={driveCommentHistory}
        setVisibleCommentDrawer={setVisibleCommentDrawer}
        userInternComments={userInternComments}
        internalCommentBOV1={internalCommentBOV1}
        loading={loading.deactivatedUserHistory ||
          loading.reactivatedUserHistory ||
          loading.driveCommentHistory ||
          loading.userInternComments}
      /> : <EmptyModerationDrawer />,
    },
    {
      label: t('RECIPIENT_NOTIFICATION'),
      key: 'userRating',
      children: <UserRatingHistory />,
    },
  ];

  return (
    <Drawer
      title={t('MODERATION_HISTORY_TITLE')}
      placement="right"
      width={window.innerWidth <= 630 ? '100%' : 630}
      open={visible}
      onClose={onClose}
      footer={
        <>
          <Button
            type='primary'
            size='big'
            onClick={showUserBanishmentFormDrawer}
            disabled={isBanishFromAllDrive}
            danger
            css={{
              marginBottom: `${window.innerWidth <= 630 ? '20px' : '0px'}`,
              marginRight: '20px',
            }}
          >
            {t('DEACTIVATION_BUTTON')}
          </Button>
          <Button
            type='primary'
            size='big'
            onClick={showUserCommentFormDrawer}
          >
            {t('ADD_COMMENT_HISTORY_BUTTON')}
          </Button>
        </>
      }
      bodyStyle={{ paddingTop: '0px' }}
    >
      {statusOnError ? <Error504 /> : null}
      <Tabs
        items={tabsItems}
        animated={true}
        activeKey={activeTab}
        onChange={(key) => setActiveTab(key)}
        renderTabBar={ (props, DefaultTabBar) => (
          <div css={{ position: 'sticky', top: '0', zIndex: 1, marginBottom: '20px', background: '#fff' }}
          >
            <DefaultTabBar
              {...props}
            />
          </div>
        )}
      />

      <UserBanishmentFormDrawer
        isBanUser={isBanUser}
        childVisible={childVisible}
        setChildVisible={setChildVisible}
        setIsBanUser={setIsBanUser}
        setIsBanishFromAllDrive={setIsBanishFromAllDrive}
        getUserDeactivationReasons={getUserDeactivationReasons}
        setVisible={setVisible}
      />
      <UserCommentFormDrawer
        visibleCommentDrawer={visibleCommentDrawer}
        setVisibleCommentDrawer={setVisibleCommentDrawer}
        setUserInternComments={setUserInternComments}
        userInternComments={userInternComments}
        commentForm={commentForm}
        setActiveTab={setActiveTab}
      />
    </Drawer>
  );
}

export default UserModerationHistoryDrawer;
