import { buildCustomHtml } from '@shopopop/backoffice-frontend-utils';
import { Button, Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import NotFound from '../../assets/404.svg';

const { Text } = Typography;

function EmptyNotFound({ onClick }: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <Empty
      image={String(NotFound)}
      imageStyle={{
        marginBottom: 13,
      }}
      description={<Text type="secondary">{buildCustomHtml(t('404_ERROR'))}</Text>}
    >
      <Button type="primary" onClick={onClick}>
        {t('404_ERROR_BUTTON')}
      </Button>
    </Empty>
  );
}

export default EmptyNotFound;
