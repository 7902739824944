import { ReactNode, useState } from 'react';
import { Card, Radio, Input, Form, Tooltip, theme, Row, Col, Space } from 'antd';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { NO_EMOJI_REGEX } from '@shopopop/backoffice-frontend-utils';
import { useScreenSize } from '@shopopop/react-hooks';

function TransportInfoCard(): ReactNode {
  const { t } = useTranslation();
  const { mobileMode } = useScreenSize();
  const { token } = theme.useToken();
  const [value, setValue] = useState<string | undefined>(undefined);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  function generateTransportOptions() {
    const transportOptions = [
      { name: 'PEDESTRIAN', value: 'Foot' },
      { name: 'BIKE', value: 'Bike' },
      { name: 'CAR', value: 'Car' },
      { name: 'TRUCK', value: 'Truck' },
    ];

    return transportOptions.map((option) => (
      <Radio.Button value={option.value} style={{ flex: 1, textAlign: 'center' }}>
        {t(option.name)}
      </Radio.Button>
    ));
  }

  return (
    <Card
      title={t('CTP_INFO_TITLE')}
      extra={
        <Tooltip title={t('TOOLTIP_CTP_INFO')}>
          <InfoCircleTwoTone twoToneColor={token.colorPrimary} />
        </Tooltip>
      }
    >
      <Row>
        <Col xs={24} xl={12}>
          <Form.Item
            label={t('ORDER_TRANSPORTATION')}
            name="transport"
            rules={[
              {
                required: true,
                message: t('TRANSPORT_MODE_REQUIRED'),
              },
            ]}
          >
            <Radio.Group
              optionType={mobileMode ? 'default' : 'button'}
              onChange={onChange}
              value={value}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              {mobileMode ? <Space direction={'vertical'}>{generateTransportOptions()}</Space> : generateTransportOptions()}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label={t('ORDER_ADDITIONAL_INFO')}
        name="additionalInfos"
        rules={[
          {
            pattern: NO_EMOJI_REGEX,
            message: t('INVALID_VALUE'),
          },
        ]}
      >
        <Input.TextArea maxLength={255} showCount placeholder={t('ORDER_ADDITIONAL_INFO_PLACEHOLDER')} />
      </Form.Item>
    </Card>
  );
}

export default TransportInfoCard;
