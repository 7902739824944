import { formatHoursDuration } from '@shopopop/backoffice-frontend-utils';
import { Alert, Card, FormInstance } from 'antd';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import DeliveryTimeBody from '../DateSelector/DeliveryTimeForm';

interface DeliveryDateTimeProps {
  form: FormInstance;
  timeSlotDelay: number;
  onBoardingRef: React.RefObject<HTMLDivElement>;
}

function DeliveryDateTime({ form, timeSlotDelay, onBoardingRef }: Readonly<DeliveryDateTimeProps>): ReactNode {
  const { t } = useTranslation();

  return (
    <Card title={t('DATE_TIMESLOT_TITLE')}>
      <DeliveryTimeBody form={form} preventionDelay={timeSlotDelay} onBoardingRef={onBoardingRef} />
      <Alert message={t('DELIVERY_TIMESLOT_INSTRUCTION').replace('[CREATION_TIME]', formatHoursDuration(timeSlotDelay))} type="info" showIcon />
    </Card>
  );
}

export default DeliveryDateTime;
