type ElevatorStatus = 'LIFT_YES' | 'LIFT_NO' | 'LIFT_UNKNOWN';

const transformElevatorForHuman = (elevator: unknown): ElevatorStatus => {
  if (typeof elevator === 'number') return elevator ? 'LIFT_YES' : 'LIFT_NO';
  if (elevator === true) return 'LIFT_YES';
  if (elevator === false) return 'LIFT_NO';
  return 'LIFT_UNKNOWN';
};

export default transformElevatorForHuman;
