export enum DeliveryEventEnum {
  BOOKED = 'BOOKED',
  CANCELED = 'CANCELED',
  GOING_TO_DRIVE = 'GOING_TO_DRIVE',
  ARRIVED_AT_DRIVE = 'ARRIVED_AT_DRIVE',
  ARRIVED_AT_CLIENT = 'ARRIVED_AT_CLIENT',
  VALIDATED = 'VALIDATED',
  GOING_TO_CLIENT = 'GOING_TO_CLIENT',
  REMOVED = 'REMOVED',
  ADDED = 'ADDED',
  DELIVERY_INCIDENT = 'DELIVERY_INCIDENT', // retrocompat
  INCIDENT = 'INCIDENT', // retrocompat avec le BOV1 mais normalment pas present
  INCIDENT_MAJOR = 'INCIDENT_MAJOR',
  INCIDENT_MINOR = 'INCIDENT_MINOR',
}

export enum DeliveryStatusEnum {
  Available = 'available', // 1
  Reserved = 'reserved', // 2
  ToPickUpPoint = 'toPickUpPoint', // 3
  AtPickUpPoint = 'atPickUpPoint', // 4
  ToRecipient = 'toRecipient', // 5
  DeliveredAndFinished = 'deliveredAndFinished', // 6 & 8
}

export enum DeliveryListTabNames {
  Schedule = 'schedule',
  InProgress = 'inProgress',
  Interrupted = 'interrupted',
  DeliveredAndFinished = 'deliveredAndFinished',
}
