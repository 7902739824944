import { DatePicker as AntDatePicker, Form } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { CustomDatePickerProps, DayJsTimeSlot } from '../../interfaces/CustomDatePicker';

const { RangePicker } = AntDatePicker;

function CustomDatePicker({ form, setSelectedRange, sameDay = false, maxDate, selectedRange, labelRangePicker, verticalMode, required = false }: Readonly<CustomDatePickerProps>): JSX.Element {
  const { t } = useTranslation();

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null]) => {
    if (dates[0] && dates[1]) {
      const newRange: DayJsTimeSlot = [dayjs(dates[0]), dayjs(dates[1]).endOf('day')];
      setSelectedRange(newRange);
      form.setFieldsValue({
        deliveryDate: sameDay ? newRange[0].toISOString() : '',
        startDate: newRange[0],
        endDate: newRange[1],
        timeRange: newRange,
      });
    }
  };

  const validateEndDate = (_: unknown, value: Dayjs) => {
    const startDate = form.getFieldValue('startDate');
    if (value?.isBefore(startDate)) {
      return Promise.reject(new Error(t('END_DATE_MUST_BE_AFTER_START_DATE')));
    }

    return Promise.resolve();
  };

  return verticalMode ? (
    <>
      <Form.Item label={t('START_DATE')} name="startDate" rules={
        [{ required: required, message: t('START_DATE_REQUIRED') }]
      }>
        <AntDatePicker
          style={{ width: '100%' }}
          format={'DD MMMM YYYY'}
          value={selectedRange[0]}
          onChange={(date) => {
            date && handleRangeChange([date, selectedRange[1]]);
          }}
          allowClear={false}
          minDate={dayjs().subtract(3, 'month').startOf('day')}
          maxDate={maxDate ?? dayjs().add(10, 'years').startOf('day')}
        />
      </Form.Item>
      <Form.Item label={t('END_DATE')} name="endDate" rules={[{ validator: validateEndDate }, { required: required, message: t('END_DATE_REQUIRED') }]} >
        <AntDatePicker
          style={{ width: '100%' }}
          format={'DD MMMM YYYY'}
          value={selectedRange[1]}
          onChange={(date) => {
            date && handleRangeChange([selectedRange[0], date]);
          }}
          allowClear={false}
          minDate={selectedRange[0]}
          maxDate={maxDate ?? dayjs().add(10, 'years').startOf('day')}
        />
      </Form.Item>
    </>
  ) : (
    <Form.Item label={t(labelRangePicker)} name="timeRange" rules={
      [{ required: required, message: t('DATE_RANGE_REQUIRED') }]
    }>
      <RangePicker
        style={{ width: '100%' }}
        format={'DD MMMM YYYY'}
        value={selectedRange}
        onChange={(values) => {
          values && handleRangeChange([values[0], values[1]]);
        }}
        allowClear={false}
        minDate={dayjs().subtract(3, 'month').startOf('day')}
        maxDate={maxDate ?? dayjs().add(10, 'years').startOf('day')}
      />
    </Form.Item>
  );
}

export default CustomDatePicker;
