import styled from '@emotion/styled';
import { Flex, Radio, theme, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface DateOption {
  labelKey: string;
  value: string;
  date: dayjs.Dayjs;
}

interface DateSelectorProps {
  dateOptions: DateOption[];
  handleDateChange: (value: string) => void;
  extraOption?: React.ReactNode;
  initialValue: string;
}

const { Text } = Typography;

function DateSelector({ dateOptions, handleDateChange, extraOption, initialValue }: Readonly<DateSelectorProps>) {
  const {
    token: { screenLG, colorPrimary, screenSM },
  } = theme.useToken();
  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState<string>(initialValue);

  useEffect(() => {
    setSelectedDate(initialValue);
  }, [initialValue]);

  const onChange = (value: string) => {
    setSelectedDate(value);
    handleDateChange(value);
  };

  return (
    <Radio.Group value={selectedDate} onChange={(e) => onChange(e.target.value)} optionType={'button'} style={{ width: '100%', display: 'flex' }}>
      {dateOptions.map((option) => {
        return (
          <StyledRadioButton key={option.value} value={option.value} colorPrimary={colorPrimary} screenSM={screenSM} screenLG={screenLG}>
            <Flex vertical>
              <Text strong>{t(option.labelKey)}</Text>
              <Text className="hide-mobile">{option.date.format('dddd D MMMM').charAt(0).toUpperCase() + option.date.format('dddd D MMMM').slice(1)}</Text>
            </Flex>
          </StyledRadioButton>
        );
      })}
      {extraOption}
    </Radio.Group>
  );
}

const StyledRadioButton = styled(Radio.Button)<{ colorPrimary: string; screenSM: number; screenLG: number }>`
    height: auto;
    padding: 5px 16px;
    text-align: center;
    outline: 0 !important;

    @media (max-width: ${({ screenLG }) => screenLG}px) {
        width: calc(100% / 3);

        .ant-typography {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .hide-mobile {
            display: none;
        }
    }

    @media (max-width: ${({ screenSM }) => screenSM}px) {
        .ant-picker {
            height: auto !important;
        }
    }

    &.ant-radio-button-wrapper-checked .ant-typography {
      color: ${({ colorPrimary }) => colorPrimary};
    }

    .anticon {
        color: ${({ colorPrimary }) => colorPrimary};
    }
`;

export default DateSelector;
