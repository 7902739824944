import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const formatDateHoursAndMinutes = (date: string): string => dayjs(date).format('LT');

const formatDateFullDate = (date: string): string => dayjs(date).format('LL');

const formatDateWithSlashes = (date: string, displayYear = false): string => {
  const dateWithSlashes = dayjs(date).format('L');
  return displayYear ? dateWithSlashes : dateWithSlashes.substring(0, 5);
};

// remove last zero to avoid formats like 2h0 => 2h
const formatHoursDuration = (hours: number): string => dayjs.duration(hours, 'hours').format('H[h]m').replace(/h0$/, 'h');

export { formatDateFullDate, formatDateHoursAndMinutes, formatDateWithSlashes, formatHoursDuration };
