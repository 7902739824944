import { ReloadOutlined } from '@ant-design/icons';
import { buildCustomHtml } from '@shopopop/backoffice-frontend-utils';
import { Button, Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import TimeoutError from '../../assets/504.svg';

interface EmptyTimeoutProps {
  onClick: () => void;
  isLoading: boolean;
}

const { Text } = Typography;

function EmptyTimeout({ onClick, isLoading }: EmptyTimeoutProps) {
  const { t } = useTranslation();

  return (
    <Empty image={String(TimeoutError)} imageStyle={{ marginBottom: 16 }} description={<Text type="secondary">{buildCustomHtml(t('504_ERROR'))}</Text>}>
      <Button type="primary" icon={<ReloadOutlined />} onClick={onClick} loading={isLoading}>
        {t('REFRESH_BUTTON')}
      </Button>
    </Empty>
  );
}

export default EmptyTimeout;
