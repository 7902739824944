import { LeftOutlined, MenuOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import styled from '@emotion/styled';
import { useMenuItems, useScreenSize } from '@shopopop/react-hooks';
import { Button, Layout, Menu, theme } from 'antd';
import { ComponentType, Dispatch, ReactNode, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const { Sider } = Layout;

export interface SideMenuProps {
  menuItems: {
    key: string;
    path?: string;
    icon: ComponentType;
    isLogout?: boolean;
  }[];
  logoComponent: ReactNode;
  siderCollapsed: boolean;
  setSiderCollapsed: Dispatch<SetStateAction<boolean>>;
  onLogout: () => void;
  createFormUrl?: string;
}

/**
 * SideMenu component
 */
function SideMenu({ menuItems, logoComponent, siderCollapsed, setSiderCollapsed, onLogout, createFormUrl }: Readonly<SideMenuProps>): ReactNode {
  const { t } = useTranslation();
  const { antdMenuItems, activeKey } = useMenuItems({ menuItems, onLogout });
  const { mobileMode, width } = useScreenSize();
  const { token } = theme.useToken();

  // Close the mobile menu when clicking on a menu item
  const collapseMobileMenu = () => {
    if (mobileMode) {
      setSiderCollapsed(true);
    }
  };

  return (
    <Sider
      collapsible
      breakpoint="md"
      width={256}
      collapsedWidth={width <= token.screenSM ? 0 : 80}
      collapsed={siderCollapsed}
      onCollapse={(value) => setSiderCollapsed(value)}
      trigger={<SideMenuTrigger siderCollapsed={siderCollapsed} setSiderCollapsed={setSiderCollapsed} />}
      style={{
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0,
        zIndex: 1000,
      }}
    >
      <div onClick={collapseMobileMenu}>{logoComponent}</div>
      <Link to={createFormUrl ?? '/delivery'}>
        <CreateDeliveryStyledWrapper>
          <Button
            type="primary"
            size="large"
            style={{
              fontSize: '14px',
              width: !siderCollapsed ? '215px' : 'auto',
              minWidth: '48px',
              borderRadius: '32px',
              maxWidth: '215px',
              overflow: 'hidden',
              transition: 'all 0s',
            }}
            shape={siderCollapsed ? 'circle' : 'round'}
            icon={<PlusOutlined style={siderCollapsed ? { lineHeight: '0px' } : {}} />}
            onClick={collapseMobileMenu}
            name="delivery"
          >
            {!siderCollapsed && t('MENU_NEW_DELIVERY')}
          </Button>
        </CreateDeliveryStyledWrapper>
      </Link>
      <Menu selectedKeys={[activeKey]} mode="inline" items={antdMenuItems} onClick={collapseMobileMenu} />
    </Sider>
  );
}

interface SideMenuTriggerProps {
  siderCollapsed: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSiderCollapsed: any;
}

/**
 * SideMenuTrigger component
 */
function SideMenuTrigger({ siderCollapsed, setSiderCollapsed }: SideMenuTriggerProps): ReactNode {
  const { token } = theme.useToken();
  const { width } = useScreenSize();

  return width <= token.screenSM ? (
    <div style={{ backgroundColor: token.colorPrimaryBg }}>
      <MenuOutlined style={{ color: token.colorPrimary }} />
    </div>
  ) : (
    <div
      onClick={() => setSiderCollapsed(!siderCollapsed)}
      style={{
        cursor: 'pointer',
        color: token?.Menu?.itemColor,
        backgroundColor: token?.Menu?.itemBg,
      }}
    >
      {siderCollapsed ? <RightOutlined /> : <LeftOutlined />}
    </div>
  );
}

const CreateDeliveryStyledWrapper = styled.div`
    text-align: center;
    margin: 0 0 20px;
  `;

export default SideMenu;
