const formatElevatorFormDataToApi = (value?: string | null): boolean | null | undefined => {
  if (!value) return null;

  const valuesToApi: Record<string, boolean | null> = {
    yes: true,
    no: false,
    dontknow: null,
  };

  return valuesToApi[value];
};

export default formatElevatorFormDataToApi;
