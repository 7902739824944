import styled from '@emotion/styled';
import { useScreenSize } from '@shopopop/react-hooks';
import type { StepProps } from 'antd';
import { Steps, theme } from 'antd';

interface CustomStepperWithGradientProps {
  items: StepProps[];
  current: number;
  status: 'process' | 'error';
}

function CustomStepperWithGradient({ items, current, status }: Readonly<CustomStepperWithGradientProps>): JSX.Element {
  const { tabletMode } = useScreenSize();
  const {
    token: { colorPrimary, colorError },
  } = theme.useToken();

  return (
    <StepperCustomError
      progressDot
      colorError={colorError}
      colorPrimary={colorPrimary}
      mobileMode={tabletMode}
      size="small"
      current={current}
      status={status}
      direction={tabletMode ? 'vertical' : 'horizontal'}
      items={items}
    />
  );
}

interface StepperCustomErrorProps {
  mobileMode: boolean;
  colorPrimary: string;
  colorError: string;
}
const StepperCustomError = styled(Steps)<StepperCustomErrorProps>`
.ant-steps-next-error .ant-steps-item-tail::after {
  background: ${({ colorError, colorPrimary, mobileMode }) => `linear-gradient(${mobileMode ? 'to bottom' : 'to right'}, ${colorPrimary} 50%, ${colorError} 50%) !important;`}
}
.ant-steps-item-error .ant-steps-item-description {
  font-weight: bold;
}
`;

export default CustomStepperWithGradient;
