import { buildCustomHtml } from '@shopopop/backoffice-frontend-utils';
import { Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import BigEmpty from '../../assets/shopopop-empty.svg';

const { Text, Link } = Typography;
function EmptyNobody({ handleClickLink, noResultLinkMessage }: { handleClickLink: () => void; noResultLinkMessage: string }) {
  const { t } = useTranslation();

  return (
    <Empty
      image={String(BigEmpty)}
      imageStyle={{
        marginBottom: 26,
      }}
      description={
        <>
          <Text type="secondary">{buildCustomHtml(t('SELECT_RCP_MSG'))}</Text>
          <Link underline onClick={handleClickLink}>
            {t(noResultLinkMessage)}
          </Link>
        </>
      }
    />
  );
}

export default EmptyNobody;
