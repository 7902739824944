import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { commonThemeTokens, themeAdmin, themePro } from '@shopopop/themes-antd';
import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import { getI18nFile } from '../src/utils/getI18nFile';
import ErrorBoundary from './components/Errors/ErrorBoundary';
import LayoutWrapper from './components/Wrapper/LayoutWrapper';
import { ContextWrapper } from './context/AppContext';
import { NotificationCenterContextWrapper } from './context/NotificationCenterContext';
import i18n from './i18n/i18n';
import menuActionReducer from './reducers/ActionMenu/menuActionReducer';
import { combineReducers, initialState } from './reducers/combineReducers';
import deliveriesReducer from './reducers/deliveries/deliveriesReducer';
import deliveryReducer from './reducers/delivery/deliveryReducer';
import userReducer from './reducers/user/userReducer';
import routes from './routes/routes';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import ProtectedRoute from './components/Login/Auth/ProtectedRoute';
import captchaReducer from './reducers/captcha/captchaReducer';
import deliveryListReducer from './reducers/DeliveryList/deliveryListReducer';
import AuthProvider from './services/AuthProvider';
import AuthService from './services/AuthService';
import { clientApm } from './services/clientApm';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrBefore);
// eslint-disable-next-line @typescript-eslint/no-var-requires
const dayOfYear = require('dayjs/plugin/dayOfYear');
dayjs.extend(dayOfYear);
dayjs.extend(duration);

const themeName = process.env.REACT_APP_THEME || 'admin';
let theme = themeAdmin;
if (themeName === 'pro') theme = themePro;

const sentryEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT;
const apmEnv = process.env.REACT_APP_APM_ENVIRONMENT;

// You must combine all reducer here
const appReducers = combineReducers({
  user: userReducer,
  delivery: deliveryReducer,
  deliveryList: deliveryListReducer,
  deliveries: deliveriesReducer,
  menuOption: menuActionReducer,
  captcha: captchaReducer,
});

if (sentryEnv) {
  Sentry.init({
    dsn: 'https://a53c1f546055495fb841f66bf8c080e3@o398789.ingest.sentry.io/5903552',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0,
    environment: sentryEnv,
  });
}


if (apmEnv) {
  clientApm.start({
    serviceName: 'BackOffice',
    serverUrl: 'https://apm-server-bjrrtkexwua55llcryo4-elasticsearch.services.clever-cloud.com/',
    environment: apmEnv,
  });
}

// Return to top on all page changes
const ScrollToTop = () => {
  useEffect(() => window.scrollTo(0, 0), [useLocation().pathname]);
  return null;
};

const App = () => {
  return (
    <ConfigProvider
      locale={getI18nFile(i18n.languages)}
      theme={{
        ...theme,
        token: {
          ...theme.token,
          ...commonThemeTokens,
        },
      }}
    >
      <ContextWrapper reducer={appReducers} initialState={initialState}>
        <NotificationCenterContextWrapper>
          <ErrorBoundary>
            <Router>
              <ScrollToTop />
              <AuthProvider>
                <Routes>
                  {routes.map((route, index) => {
                    return (
                      <Route
                        key={index}
                        path={route.path}
                        title={route.title}
                        element={
                          <ProtectedRoute roles={route.roles}>
                            <LayoutWrapper showMenu={route.showMenu}>
                              {route.element}
                            </LayoutWrapper>
                          </ProtectedRoute>
                        }
                      />
                    );
                  })}
                </Routes>
              </AuthProvider>
            </Router>
          </ErrorBoundary>
        </NotificationCenterContextWrapper>
      </ContextWrapper>
    </ConfigProvider>
  );
};

const renderApp = () => {
  createRoot(document.getElementById('root')).render(<App />);
};

AuthService.initKeycloak(renderApp);
