/** @jsxImportSource @emotion/react */
import { WalletOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useRouter } from '../../../hooks/useRouter';
import TouchableView from '../../DeviceDetect/TouchableView';
import UntouchableView from '../../DeviceDetect/UntouchableView';
import UserBanishmentButtonDrawer from '../Moderation/UserBanishment/UserBanishmentButtonDrawer';

/**
 * CallsToActions
 * @param {Function} setUserDeactivationReasons
 * @param {Object} userDeactivationReasons
 * @param {Boolean} disabledBanButton
 * @param {Function} setDisabledBanButton
 * @param {Boolean} isBanUser
 * @param {Function} setIsBanUser
 * @param {Function} getUserDeactivationReasons
 * @param {Array} internalCommentBOV1
 * @param {Boolean} mobile
 * @return {JSX.Element}
 */
export default function CallsToActions({
  userDeactivationReasons,
  disabledBanButton, setDisabledBanButton,
  isBanUser, setIsBanUser,
  getUserDeactivationReasons, internalCommentBOV1, mobile,
}) {
  return (
    <>
      {
        mobile ?
          <Space size={10} css={{ marginBottom: 20 }}>
            <UserBanishmentButtonDrawer
              userDeactivationReasons={userDeactivationReasons}
              disabledBanButton={disabledBanButton}
              setDisabledBanButton={setDisabledBanButton}
              isBanUser={isBanUser}
              setIsBanUser={setIsBanUser}
              getUserDeactivationReasons={getUserDeactivationReasons}
              internalCommentBOV1={internalCommentBOV1}
            />
            <PaymentButton />
          </Space> :
          <Space size={10}>
            <PaymentButton />
            <UserBanishmentButtonDrawer
              userDeactivationReasons={userDeactivationReasons}
              disabledBanButton={disabledBanButton}
              setDisabledBanButton={setDisabledBanButton}
              isBanUser={isBanUser}
              setIsBanUser={setIsBanUser}
              getUserDeactivationReasons={getUserDeactivationReasons}
              internalCommentBOV1={internalCommentBOV1}
            />
          </Space>
      }
    </>
  );
}

/**
 * PaymentButton
 * @return {JSX.Element}
 */
export function PaymentButton() {
  const { t } = useTranslation();
  const router = useRouter();
  const userId = router.query.id;

  return (
    router.query.tab !== 'recipient' &&
    <Link
      to={`${process.env.REACT_APP_OLD_BO_URL}/users/${userId}?tab=mango`}
      target='_blank'
    >
      <UntouchableView>
        <Button
          icon={<WalletOutlined />}>
          {t('PAYMENTS')}
        </Button>
      </UntouchableView>
      <TouchableView>
        <Button
          icon={<WalletOutlined />} />
      </TouchableView>
    </Link>
  );
}
