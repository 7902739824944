import dayjs, { Dayjs } from 'dayjs';
import { roundToNextQuarterHour } from '..';

import isBetween from 'dayjs/plugin/isBetween';
dayjs.extend(isBetween);

interface InitialTimesProps {
  preventionDelay: number;
  now: Dayjs;
}

interface InitialTimes {
  start: Dayjs;
  end: Dayjs;
}

const MORNING_START_HOUR = 10;
const AFTERNOON_END_HOUR = 18;
const DEFAULT_DURATION_HOURS = 2;

const hoursClosedMorning = ({ now }: InitialTimesProps): InitialTimes => {
  const start = roundToNextQuarterHour(now).hour(MORNING_START_HOUR).minute(0);

  return { start, end: start.add(DEFAULT_DURATION_HOURS, 'hour') };
};

const hoursOpened = ({ preventionDelay, now }: InitialTimesProps): InitialTimes => {
  const isExactQuarter = now.minute() % 15 === 0;
  const start = roundToNextQuarterHour(now)
    .add(preventionDelay, 'hour')
    .add(isExactQuarter ? 15 : 0, 'minute');

  return { start, end: start.add(DEFAULT_DURATION_HOURS, 'hour') };
};

const endOfOpeningHours = ({ preventionDelay, now }: InitialTimesProps): InitialTimes => {
  return {
    start: roundToNextQuarterHour(now).add(preventionDelay, 'hour'),
    end: dayjs(now).hour(21).minute(0),
  };
};

const hoursClosedAfternoon = ({ now }: InitialTimesProps): InitialTimes => {
  const start = dayjs(now).add(1, 'day').hour(MORNING_START_HOUR).minute(0);

  return { start, end: start.add(DEFAULT_DURATION_HOURS, 'hour') };
};

function calculateInitialTimes(times: InitialTimesProps): InitialTimes {
  const { now } = times;
  const currentHour = now.hour();

  if (currentHour >= 0 && currentHour < 8) {
    return hoursClosedMorning(times);
  }

  if (currentHour >= 8 && currentHour < AFTERNOON_END_HOUR) {
    if (now.isBetween(dayjs(now).hour(17).minute(0), dayjs(now).hour(18).minute(0))) {
      // Cas entre 17h00 et 18h
      return endOfOpeningHours(times);
    } else {
      // Cas entre 8h et 18h
      return hoursOpened(times);
    }
  } else {
    return hoursClosedAfternoon(times);
  }
}

export default calculateInitialTimes;
