import styled from '@emotion/styled';
import { DEFAULT_PAGINATION } from '@shopopop/backoffice-frontend-utils';
import { DeliveryListTabNames, DeliveryStatusEnum } from '@shopopop/react-hooks';
import { ConfigProvider, Table, TablePaginationConfig, theme } from 'antd';
import { FilterValue } from 'antd/es/table/interface';
import dayjs from 'dayjs';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DeliveryListItemProps } from '../../interfaces/DeliveryListProps.ts';
import EmptyNoDelivery from '../Empty/EmptyNoDelivery.tsx';
import EnhancedTableOnDate from '../Tables/common/EnhancedTableOnDate';
import { getColumns } from './columns/getcolumns.tsx';

interface DeliveriesListProps {
  deliveries: DeliveryListItemProps[];
  currentTab: DeliveryListTabNames;
  loading: boolean;
  total: number;
}

const StyledTable = styled(Table<DeliveryListItemProps>)<{ borderColor: string; borderRadius: number }>`
.ant-table-content {
  border: 1px solid ${(props) => props.borderColor} !important;
  border-radius:${(props) => props.borderRadius}px ${(props) => props.borderRadius}px 0 0 !important;
}
`;

function DeliveriesList({ deliveries, currentTab, loading, total }: Readonly<DeliveriesListProps>): ReactNode {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(DEFAULT_PAGINATION.current);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGINATION.pageSize);
  const searchParams = new URLSearchParams(location.search);
  const multidrive = searchParams.getAll('drive_id').length > 1;

  useEffect(() => {
    setCurrentPage(Number(searchParams.get('page')) || DEFAULT_PAGINATION.current);
    setPageSize(Number(searchParams.get('per_page')) || DEFAULT_PAGINATION.pageSize);
  }, [searchParams]);

  const handleTableChange = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>) => {
    const { current, pageSize } = pagination;
    const { status: statusFilters } = filters;
    setCurrentPage(current as number);
    setPageSize(pageSize as number);

    const searchParams = new URLSearchParams(location.search);
    searchParams.set('per_page', `${pageSize}`);
    searchParams.set('page', `${current}`);

    statusFilters?.length ? searchParams.set('status', statusFilters[0] as DeliveryStatusEnum) : searchParams.delete('status');

    navigate(`?${searchParams.toString()}`);
  };
  const {
    token: { colorPrimaryBorder, borderRadius },
  } = theme.useToken();

  const withdrawalStartUtc = searchParams.get('withdrawal_start_utc');
  const withdrawalEndUtc = searchParams.get('withdrawal_end_utc');

  // Check if the deliveries are on the same day
  const isSameDay = withdrawalStartUtc && withdrawalEndUtc && dayjs(withdrawalStartUtc).isSame(dayjs(withdrawalEndUtc), 'day');

  return (
    <ConfigProvider renderEmpty={() => <EmptyNoDelivery />}>
      {isSameDay ? (
        <StyledTable
          borderColor={colorPrimaryBorder}
          borderRadius={borderRadius}
          columns={getColumns(t, navigate, multidrive, currentTab, searchParams)}
          dataSource={deliveries}
          loading={loading}
          pagination={{
            current: currentPage,
            pageSize,
            pageSizeOptions: DEFAULT_PAGINATION.pageSizeOptions,
            total,
          }}
          onChange={handleTableChange}
        />
      ) : (
        <EnhancedTableOnDate
          data={deliveries}
          loading={loading}
          columns={getColumns(t, navigate, multidrive, currentTab, searchParams)}
          onChange={handleTableChange}
          pagination={{
            current: currentPage,
            pageSize,
            pageSizeOptions: DEFAULT_PAGINATION.pageSizeOptions,
            total,
          }}
        />
      )}
    </ConfigProvider>
  );
}

export default DeliveriesList;
