import { calculateInitialTimes, createNewEndDate, createNewStartDate, formatHoursDuration, roundToNextQuarterHour } from '@shopopop/backoffice-frontend-utils';
import { Form, FormInstance } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

const DEFAULT_DURATION = 2;

const useDeliveryDateTime = (form: FormInstance, preventionDelay: number) => {
  const { t } = useTranslation();

  const { start: initialStartDate, end: initialEndDate } = calculateInitialTimes({
    now: dayjs().second(0).millisecond(0),
    preventionDelay,
  });

  useEffect(() => {
    if (!form.getFieldValue('startDate')) {
      form.setFieldValue('startDate', initialStartDate);
    }
    if (!form.getFieldValue('endDate')) {
      form.setFieldValue('endDate', initialEndDate);
    }
  }, []);

  const [initOnce, setInitOnce] = useState(false);

  const startDate: Dayjs = Form.useWatch('startDate', form);
  const endDate: Dayjs = Form.useWatch('endDate', form);

  useEffect(() => {
    if (startDate && !initOnce) {
      const roundedStartDate = roundToNextQuarterHour(startDate);
      form.setFieldsValue({
        _startTime: roundedStartDate,
        _endTime: roundToNextQuarterHour(roundedStartDate.add(2, 'hour')),
      });
      setInitOnce(true);
    }
  }, [startDate]);

  const validateTimeRange = async() => {
    if (!startDate || !endDate) {
      return Promise.reject(new Error(t('DELIVERY_TIME_REQUIRED_ERROR')));
    }
    if (startDate.isBefore(dayjs())) {
      return Promise.reject(new Error(t('DELIVERY_TIME_PAST_ERROR')));
    }

    // Vérification que l'heure de début est entre 08h00 et 20h00
    const startOfDay = startDate.hour(8).minute(0).second(0);
    const endOfDay = startDate.hour(20).minute(0).second(0);
    if (!startDate.isBetween(startOfDay, endOfDay, 'minute', '[]')) {
      return Promise.reject(new Error(t('START_TIME_OUT_OF_RANGE')));
    }

    // Vérification que l'heure de fin est entre 10h00 et 21h00
    const startOfEndRange = dayjs(endDate).hour(9).minute(0).second(0);
    const endOfEndRange = dayjs(endDate).hour(21).minute(0).second(0);
    if (!endDate.isBetween(startOfEndRange, endOfEndRange, 'minute', '[]')) {
      return Promise.reject(new Error(t('WRONG_DELIVERY_HOUR')));
    }

    if (endDate.isSameOrBefore(startDate)) {
      return Promise.reject(new Error(t('END_HOUR_IS_EQUAL_OR_BEFORE_START_HOUR')));
    }
    if (endDate.startOf('minute').diff(startDate.startOf('minute'), 'minute') < 60) {
      return Promise.reject(new Error(t('WRONG_INTERVAL_BETWEEN_DELIVERY_DATES')));
    }

    /**
     * On veux min 1h entre le début et la fin
     * On veux que ce soit à l'heure de maintenant +2H pour le début
     * On ne peux mettre des heures qu'entre 8h et 21h
     *
     * On a 2 variables dayjs à check : startDate et endDate
     *
     * Si cest règle ne sont pas respecté, il faut renvoyer :
     * return Promise.reject(new Error(t('WRONG_DELIVERY_HOUR')));
     */
    const now = dayjs();
    const minStartTime = now.add(preventionDelay, 'hour');
    const startHour = startDate.hour();
    const endHour = endDate.hour();

    const isStartTimeValid = startDate?.isAfter(minStartTime);
    const isEndTimeValid = endDate?.isAfter(startDate.add(1, 'hour').subtract(1, 'second'));
    const isStartHourInRange = startHour >= 8 && startHour <= 21;
    const isEndHourInRange = endHour >= 8 && endHour <= 21;

    if (!isStartTimeValid) {
      return Promise.reject(new Error(t('DELIVERY_DATE_TOO_CLOSE').replace('[CREATION_TIME]', formatHoursDuration(preventionDelay))));
    }

    if (!isEndTimeValid || !isStartHourInRange || !isEndHourInRange) {
      return Promise.reject(new Error('WRONG_DELIVERY_HOUR'));
    }
    return Promise.resolve();
  };

  const handleStartTimeChange = (time: dayjs.Dayjs | null) => {
    if (time) {
      const selectedDateDay = startDate.startOf('day');
      const newTime = roundToNextQuarterHour(dayjs(selectedDateDay).hour(time.hour()).minute(time.minute()));

      // Adjust the end date if start time is 20:00
      let newEndTime = newTime.add(DEFAULT_DURATION, 'hour');
      if (newTime.isSameOrAfter(dayjs().hour(19).minute(0)) && newTime.isBefore(dayjs().hour(20).minute(0))) {
        newEndTime = newTime.add(DEFAULT_DURATION, 'hour').minute(0);
      }
      if (newTime.hour() === 20) {
        newEndTime = newTime.add(1, 'hour');
      }

      form.setFieldsValue({ startDate: newTime, endDate: newEndTime });
      form.validateFields(['timeRange']);
    }
  };

  const handleEndTimeChange = (time: dayjs.Dayjs | null) => {
    if (time) {
      const selectedDateDay = startDate.startOf('day');
      const newTime = roundToNextQuarterHour(dayjs(selectedDateDay).hour(time.hour()).minute(time.minute()));
      form.setFieldsValue({ endDate: newTime });
      form.validateFields(['timeRange']);
    }
  };

  const handleDateChange = (value: string) => {
    const newStartDate = createNewStartDate(value, startDate.hour(), startDate.minute());
    const newEndDate = createNewEndDate(value, endDate.hour(), endDate.minute());

    form.setFieldsValue({
      startDate: newStartDate,
      endDate: newEndDate,
      _startTime: newStartDate,
      _endTime: newEndDate,
    });

    form.validateFields(['timeRange']);
  };

  return {
    startDate,
    endDate,
    validateTimeRange,
    handleStartTimeChange,
    handleEndTimeChange,
    handleDateChange,
  };
};

export default useDeliveryDateTime;
