import { ReloadOutlined } from '@ant-design/icons';
import { buildCustomHtml } from '@shopopop/backoffice-frontend-utils';
import { Button, Empty, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import InternalError from '../../assets/500.svg';

interface EmptyInternalErrorProps {
  onClick: () => void;
  isLoading: boolean;
}

const { Text } = Typography;

function EmptyInternalError({ onClick, isLoading }: EmptyInternalErrorProps) {
  const { t } = useTranslation();

  return (
    <Empty
      image={String(InternalError)}
      imageStyle={{
        marginBottom: 13,
      }}
      description={<Text type="secondary">{buildCustomHtml(t('500_ERROR'))}</Text>}
    >
      <Button type="primary" icon={<ReloadOutlined />} onClick={onClick} loading={isLoading}>
        {t('REFRESH_BUTTON')}
      </Button>
    </Empty>
  );
}

export default EmptyInternalError;
