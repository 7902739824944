import { SettingTwoTone } from '@ant-design/icons';
import { countryAlphaOrder } from '@shopopop/backoffice-frontend-utils';
import { mapI18N, useChangeLanguage } from '@shopopop/react-hooks';
import type { MenuProps } from 'antd';
import { Dropdown, Menu, theme } from 'antd';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type MenuItem = Required<MenuProps>['items'][number];

/**
 * The MyAccount component.
 * @return {ReactNode} The MyAccount component.
 */
function MyAccount(): ReactNode {
  const { t, i18n } = useTranslation();
  const handleChangeLanguage = useChangeLanguage();
  const { token } = theme.useToken();

  const languages = useMemo(
    () =>
      countryAlphaOrder(mapI18N).map(({ name }) => ({
        key: name,
        label: name,
        onClick: () => handleChangeLanguage(name),
      })),
    [mapI18N],
  );
  const selectedLanguage = useMemo(() => mapI18N.find(({ keys }) => keys.includes(i18n.language))?.name, [i18n.language, mapI18N]);

  const items: MenuItem[] = [
    {
      key: 'languages',
      label: t('LANGUAGE_CHOICE'),
      type: 'group',
      children: languages,
    },
  ];

  return (
    <Dropdown dropdownRender={() => <Menu items={items} mode="inline" activeKey={selectedLanguage ?? ''} />}>
      <SettingTwoTone twoToneColor={token.colorPrimary} style={{ fontSize: 20, verticalAlign: 'bottom' }} />
    </Dropdown>
  );
}

export default MyAccount;
