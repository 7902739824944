import { React, useState } from 'react';
import { useTranslation } from 'react-i18next';
// Components
import { FieldTimeOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import UserModerationHistoryDrawer from '../../../Drawers/Moderation/UserModerationHistoryDrawer';

/**
  * UserBanishmentButtonDrawer
  * @return {JSX.Element}
  * @param {boolean} isBanUser
  * @param {Function} setIsBanUser
  * @param {Array} internalCommentBOV1
  * @param {Object} userDeactivationReasons
 */
function UserBanishmentButtonDrawer({ isBanUser, setIsBanUser, userDeactivationReasons, internalCommentBOV1, getUserDeactivationReasons }) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  /**
   * openBanishmentDrawer - Open the banishment drawer
   */
  function openBanishmentDrawer() {
    setVisible(true);
  }

  return (
    <>
      <Button
        type='primary'
        onClick={openBanishmentDrawer}
        icon={<FieldTimeOutlined />}
        id='button-banishment'
      >
        {t('MODERATION_HISTORY_TITLE')}
      </Button>
      <UserModerationHistoryDrawer
        visible={visible}
        setVisible={setVisible}
        isBanUser={isBanUser}
        setIsBanUser={setIsBanUser}
        userDeactivationReasons={userDeactivationReasons}
        internalCommentBOV1={internalCommentBOV1}
        getUserDeactivationReasons={getUserDeactivationReasons}
      />
    </>
  );
}

export default UserBanishmentButtonDrawer;
