import { init as initApm } from '@elastic/apm-rum';

/**
 * isUrlValid
 *
 * @param {string} url
 * @return {boolean}
 */
function isUrlValid(url) {
  try {
    return Boolean(new URL(url));
  } catch {
    return false;
  }
}

/**
 * Client Apm
 */
class ClientApm {
  #clientApm;
  #apm;

  /**
   * Constructor
   */
  constructor() {
    this.#apm = initApm;
  }

  /**
   * Start
   *
   * @param {object} options
   * @param {string} options.serviceName
   * @param {string} options.serverUrl
   * @param {string} options.environment
   * @param {string} options.version
   * @return {void}
   */
  start(options) {
    if (isUrlValid(options.serverUrl)) {
      this.#clientApm = this.#apm({
        serviceName: options.serviceName,
        serverUrl: options.serverUrl,
        environment: options.environment,
        serviceVersion: options.version,
      });
    }
  }

  /**
   * setUserContext
   *
   * @param {object} userContext
   * @param {string | number} userContext.id
   * @param {string} userContext.email
   * @param {string | number} userContext.username
   * @return {void}
   */
  setUserContext(userContext) {
    // Guard
    if (this.#clientApm === undefined) {
      return;
    }

    this.#clientApm.setUserContext(userContext);
  }
}

export const clientApm = new ClientApm();
