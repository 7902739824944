/** @jsxImportSource @emotion/react */
import { Card, Typography } from 'antd';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { colors } from '../../globalVar/theme';

const { Text } = Typography;

const ExpandButton = ({ expanded, onToggle, labelShowMore, labelShowLess }) => (
  <span
    onClick={onToggle}
    css={{ color: colors.primary, textDecoration: 'underline', cursor: 'pointer' }}
  >
     ...{expanded ? labelShowLess : labelShowMore}
  </span>
);

const getVisibleContent = (comment, maxLength, expanded) => {
  if (expanded || comment.length <= maxLength) {
    return comment.split('\n');
  }
  const truncated = comment.substring(0, maxLength);
  const lastLineBreakIndex = truncated.lastIndexOf('\n');
  const visibleContent =
    lastLineBreakIndex > -1 ? truncated.substring(0, lastLineBreakIndex) : truncated;
  return visibleContent.split('\n');
};

/**
 * CommentCardModerationHistory
 * @param {Object} props
 * @param {string} props.comment - Commentaire à afficher.
 * @return {JSX.Element|null}
 */
function CommentCardModerationHistory({ comment }) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const MAX_LENGTH = 125;

  const visibleContent = useMemo(
    () => getVisibleContent(comment, MAX_LENGTH, expanded),
    [comment, expanded, MAX_LENGTH]
  );

  if (!comment || typeof comment !== 'string') {
    return null;
  }

  return (
    <Card
      size="small"
      bodyStyle={{ padding: '15px' }}
      css={{
        border: `1px solid ${colors.primary}`,
        borderRadius: 4,
      }}
    >
      <Text>
        {visibleContent.map((line, index) => (
          <Fragment key={index}>
            {line}
            {index < visibleContent.length - 1 && <br />}
          </Fragment>
        ))}
      </Text>
      {comment.length > MAX_LENGTH && (
        <ExpandButton
          expanded={expanded}
          onToggle={() => setExpanded(!expanded)}
          labelShowMore={t('SHOW_MORE')}
          labelShowLess={t('SHOW_LESS')}
        />
      )}
    </Card>
  );
}

export default CommentCardModerationHistory;
