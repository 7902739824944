function distanceParser({ value, unit }: { value?: number | null | undefined; unit?: string | null | undefined }): string | null {
  if (!value || !unit) return null;

  let dist: string;
  switch (unit) {
    case 'km':
      dist = `${(value / 1000).toFixed(1)} km`;
      break;
    case 'miles':
      // Convert to miles if the distance is over 1609.34 meters (1 mile)
      dist = value >= 1609.34 ? `${(value / 1609.34).toFixed(1)} miles` : `${(value * 3.28084).toFixed(0)} ft`;
      break;
    case 'm':
      // Display in meters, no conversion needed
      dist = `${(value / 1000).toFixed(1)} km`;
      break;
    case 'ft':
      // Display in feet, converting meters to feet
      dist = `${(value * 3.28084).toFixed(0)} ft`;
      break;
    default:
      // Default to meters if the unit is not recognized
      dist = `${(value / 1000).toFixed(1)} km`;
  }

  return dist;
}

export default distanceParser;
